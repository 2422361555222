<script setup>
import { ref, computed } from 'vue';
import moment from 'moment';

const emits = defineEmits(['update:date', 'blur']);
const props = defineProps({
    date: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        required: true,
    },
    outlined: {
        type: Boolean,
        default: false,
    },
    minDate: {
        type: String,
        default: '',
    },
    maxDate: {
        type: String,
        default: '',
    },
    additionalRules: {
        type: Array,
        default: () => [],
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const dateFormat = 'MM/DD/YYYY';
const menu = ref(false);
const menuRef = ref(`${props.label.toLowerCase()}Date`);

const defaultRules = {
    validDate: (value) => {
        if (!value) return true;
        return moment(value, dateFormat, true).isValid() || 'Invalid date';
    },
};
const rules = computed(() => [ defaultRules.validDate, ...props.additionalRules ]);

const formatDate = (date) => {
    if (!date) return null;

    const [year, month, day] = date.split('-');
    return `${month}/${day}/${year}`;
};

const parseDate = (date) => {
    if (!date) return null;
    if (defaultRules.validDate(date) !== true) return null;

    return moment(date).format('YYYY-MM-DD');
};

const localDate = computed({
    get: () => props.date,
    set: (newValue) => {
        emits('update:date', newValue);
    },
});

const formattedDate = computed({
    get: () => formatDate(props.date),
    set: (newValue) => {
        localDate.value = parseDate(newValue);
    },
});

function updateFormattedDate(dateString) {
    menu.value = false;

    if (dateString && dateString.length < 10) return;
    formattedDate.value = dateString;
}
</script>

<template>

    <v-menu
        :ref="menuRef"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
        <template #activator="{ on, attrs }">
            <v-text-field
                :value="formattedDate"
                :aria-label="`${label} Start Date`"
                dense
                :outlined="outlined"
                hide-details
                clearable
                :placeholder="dateFormat"
                persistent-placeholder
                background-color="white"
                :rules="rules"
                :disabled="disabled"
                v-bind="attrs"
                @keydown.tab="menu = false"
                @blur="$emit('blur')"
                @input="(value) => updateFormattedDate(value)"
                @click:clear="localDate = null"
                v-on="on"
            >
                <template #append>
                    <v-icon @click="menu = !menu">
                        mdi-calendar
                    </v-icon>
                </template>
            </v-text-field>
        </template>
        <v-date-picker v-model="localDate" no-title :min="minDate" :max="maxDate" @input="menu = false" />
    </v-menu>
</template>