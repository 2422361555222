<template>
    <div class="sidebar-thin custom-container">
        <TheSidebar />
        <ThePageHeadingBar
            :pageHeading="$route.meta.title"
            fullSize
            backArrow
            @goBack="$router.push( { name: 'InvoiceReconciliationSearch' } )"
        />
        <section class="main">
            <v-form
                ref="form"
                noValidate
            >
                <v-container class="create-invoice">
                    <v-row class="create-invoice__form-field">
                        <v-col cols="2">
                            <label for="poNumber" class="create-invoice__form-field__label">
                                <RequiredAsterisk/>
                                PO Number
                            </label>
                        </v-col>
                        <v-col cols="5">
                            <AutocompletePurchaseOrder
                                v-model="dropdownPurchaseOrder"
                                :preselectedSearch="filters.purchaseOrderNumber"
                                :rules="[ rules.requiredObject ]"
                                dropdownItemText="numberWithStatus"
                                validateOnBlur
                                @input="setCurrentPurchaseOrderLines"
                            />
                        </v-col>
                    </v-row>
                    <v-row class="create-invoice__form-field">
                        <v-col cols="2">
                            <label for="location" class="create-invoice__form-field__label">
                                <RequiredAsterisk/>
                                Location
                            </label>
                        </v-col>
                        <v-col cols="5">
                            <DropdownLocationList
                                v-model="location"
                                outlined
                                :rules="[ rules.required ]"
                                validateOnBlur
                            />
                        </v-col>
                    </v-row>
                    <v-row class="create-invoice__form-field">
                        <v-col cols="2">
                            <label for="supplier" class="create-invoice__form-field__label">
                                <RequiredAsterisk/>
                                Supplier
                            </label>
                        </v-col>
                        <v-col cols="5">
                            <DropdownPrimarySupplier
                                v-model="primarySupplier"
                                :hideDetails="true"
                                :rules="[rules.requiredObject]"
                                validateOnBlur
                            />
                        </v-col>
                    </v-row>
                    <v-row class="create-invoice__form-field">
                        <v-col cols="2">
                            <label for="invoiceNumber" class="create-invoice__form-field__label">
                                <RequiredAsterisk/>
                                Invoice Number
                            </label>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field
                                id="invoiceNumber"
                                v-model="supplierDocumentNumber"
                                type="text"
                                outlined
                                dense
                                hideDetails
                                required
                                :rules="[ rules.required ]"
                            />
                        </v-col>
                    </v-row>
                    <v-row class="create-invoice__form-field">
                        <v-col cols="2">
                            <label for="invoiceDate" class="create-invoice__form-field__label">
                                <RequiredAsterisk/>
                                Invoice Date
                            </label>
                        </v-col>
                        <v-col cols="5">
                            <InputDatePicker
                                label="Invoice Date"
                                :date.sync="invoiceDate"
                                outlined
                                :additional-rules="[rules.required]"
                            />
                        </v-col>
                    </v-row>
                    <v-row class="create-invoice__form-field">
                        <v-col cols="2">
                            <label
                                for="invoiceTotal"
                                class="create-invoice__form-field__label"
                            >
                                <RequiredAsterisk/>
                                Invoice Total
                            </label>
                        </v-col>
                        <v-col cols="5">
                            <InputCurrency
                                id="invoiceTotal"
                                v-model.number="invoiceTotal"
                                required
                            />
                        </v-col>
                    </v-row>
                    <v-row class="create-invoice__form-field">
                        <v-col cols="2">
                            <label
                                for="invoiceNotes"
                                class="create-invoice__form-field__label"
                            >
                                Invoice Notes
                            </label>
                        </v-col>
                        <v-col cols="5">
                            <v-textarea
                                id="invoiceNotes"
                                v-model="invoiceNotes"
                                type="text"
                                outlined
                                dense
                                hideDetails
                            />
                        </v-col>
                    </v-row>
                    <v-row class="create-invoice__form-field">
                        <v-col cols="2">
                            <label
                                for="poLines"
                                class="create-invoice__form-field__label"
                            >
                                <RequiredAsterisk/>
                                Purchase Order Lines
                            </label>
                        </v-col>
                        <v-col cols="10">
                            <div class="table-purchase-order-lines">
                                <TablePurchaseOrderLines
                                    v-model="selectedPurchaseOrderLines"
                                    :items="currentPurchaseOrderLines"
                                    :error="tableError"
                                />
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </section>
        <ThePageFooterBar>
            <v-container class="create-invoice-footer">
                <v-row>
                    <v-spacer />
                    <v-btn
                        outlined
                        class="create-invoice-footer__button"
                        @click="clear"
                    >
                        Clear
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="create-invoice-footer__button"
                        @click="checkForSupplierDocumentNumber"
                    >
                        Save
                    </v-btn>
                </v-row>
            </v-container>
        </ThePageFooterBar>
        <v-dialog
            v-model="duplicateInvoiceModal"
            maxWidth="400"
            contentClass="warning"
            persistent
        >
            <v-card id="warning_modal">
                <v-card-title class="warning__title">
                    <v-icon color="warning">mdi-alert</v-icon>
                    <span>Warning Possible Duplicate</span>
                    <v-btn
                        icon aria-label="Close"
                        @click="duplicateInvoiceModal = false"
                    >
                        <v-icon color="white">
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="warning__text">
                    <p>This invoice number already exists.</p>
                    <p>Do you want to save anyway?</p>
                </v-card-text>
                <v-card-actions class="warning__buttons">
                    <v-spacer />
                        <v-btn
                            color="primary"
                            outlined
                            :to="{ name: 'InvoiceReconciliationSearch', query: {
                                invoiceNumber: supplierDocumentNumber,
                                locationId: location.id,
                                supplierId: primarySupplier.id,
                            } }"
                            target="_blank"
                        >
                            View Duplicate
                        </v-btn>
                    <v-btn
                        color="primary"
                        outlined
                        @click="save"
                    >
                        Save Anyway
                    </v-btn>
                    <v-btn
                        ref="warningModalCancelButton"
                        color="primary"
                        class="white--text"
                        @click="duplicateInvoiceModal = false"
                    >
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// Vuex
import { mapState } from 'vuex';

// Utils
import ValidationUtil from '@/utils/ValidationUtil';

// Components
import TheSidebar from '@/views/common/TheSidebar';
import ThePageHeadingBar from '@/components/ThePageHeadingBar';
import ThePageFooterBar from '@/components/ThePageFooterBar';
import DropdownPrimarySupplier from '@/components/dropdowns/DropdownPrimarySupplier';
import AutocompletePurchaseOrder from '@/components/dropdowns/AutocompletePurchaseOrder';
import DropdownLocationList from '@/views/common/dropdowns/DropdownLocationList';
import RequiredAsterisk from '@/components/base/RequiredAsterisk';
import InputCurrency from '@/components/base/inputs/InputCurrency';
import InputDatePicker from '@/components/InputDatePicker';
import TablePurchaseOrderLines from './shared/TablePurchaseOrderLines';

export default {
    name: 'InvoiceCreate',
    components: {
        InputDatePicker,
        TheSidebar,
        ThePageHeadingBar,
        ThePageFooterBar,
        TablePurchaseOrderLines,
        DropdownPrimarySupplier,
        DropdownLocationList,
        AutocompletePurchaseOrder,
        RequiredAsterisk,
        InputCurrency,
    },
    data() {
        return {
            location: {},
            primarySupplier: {},
            supplierDocumentNumber: '',
            invoiceDate: '',
            invoiceTotal: '',
            invoiceNotes: '',
            poNumber: '',
            currentPurchaseOrderLines: [],
            dropdownPurchaseOrder: {},
            duplicateInvoiceModal: false,
            duplicateInvoiceArray: [],
            selectedPurchaseOrderLines: [],
            rules: {
                required: ValidationUtil.required,
                requiredObject: ValidationUtil.requiredObject,
                requiredArray: ValidationUtil.requiredArray,
            },
            tableError: false,
        };
    },
    computed: {
        ...mapState( {
            filters: ( state ) => state.Invoice.filters,
            locationList: ( state ) => state.GlobalResources.locationList,
            primarySupplierList: ( state ) => state.GlobalResources.primarySupplierList,
        } ),
    },
    watch: {
        selectedPurchaseOrderLines() {
            this.checkTableErrorState();
        },
    },
    async beforeMount() {
        await this.$store.dispatch( 'GlobalResources/init' );
        if ( this.$router.currentRoute.query ) {
            const { locationId, supplierId } = this.$router.currentRoute.query;
            if ( locationId ) {
                this.location = this.locationList.find( ( location ) => ( location.id ).toString() === locationId ) ?? this.location;
            }
            if ( supplierId ) {
                this.primarySupplier = this.primarySupplierList.find( ( supplier ) => ( supplier.id ).toString() === supplierId ) ?? this.primarySupplier;
            }
        }
    },
    mounted() {
        this.primarySupplier = this.filters.primarySupplier;
        this.location = this.filters.location;
    },
    methods: {
        setPrimarySupplier( supplier ) {
            this.primarySupplier = supplier;
        },
        checkForSupplierDocumentNumber() {
            if ( this.validate() ) {
                this.supplierDocumentNumber ? this.checkForDuplicateInvoice() : this.save();
            }
        },
        validate() {
            this.checkTableErrorState();
            return this.$refs.form.validate() && !this.tableError;
        },
        clearValidate() {
            this.tableError = false;
            return this.$refs.form.resetValidation();
        },
        checkTableErrorState() {
            this.tableError = this.selectedPurchaseOrderLines.length === 0;
        },
        async checkForDuplicateInvoice() {
            const duplicatesArray = await this.$store.dispatch( 'Invoice/Detail/getInvoicesBySupplierAndDocumentNumber', {
                locationId: this.location.id,
                supplierId: this.primarySupplier.id,
                supplierDocumentNumber: this.supplierDocumentNumber,
            } );
            this.duplicateInvoiceArray = duplicatesArray;
            this.duplicateInvoiceArray.length > 0
                ? this.launchDuplicatesModal()
                : await this.save();
        },
        launchDuplicatesModal() {
            this.duplicateInvoiceModal = true;
            setTimeout( () => {
                this.$refs.warningModalCancelButton.$el.focus();
            } );
        },
        async save() {
            /* To avoid Vue errors until we write this */
            const invoice = {
                supplierDocumentNumber: this.supplierDocumentNumber,
                invoiceCreateDate: this.invoiceDate,
                total: this.invoiceTotal,
                notes: this.invoiceNotes,
                purchaseOrderNumber: this.dropdownPurchaseOrder.number,
                supplierId: this.primarySupplier.id,
                locationId: this.location.id,
                poItemIds: this.selectedPurchaseOrderLines.map( ( line ) => line.id ),
            };
            const id = await this.$store.dispatch( 'Invoice/create', { invoice } );
            await this.$router.push( { name: 'InvoiceReconciliationDetail', params: { id } } );
        },
        async setCurrentPurchaseOrderLines() {
            this.currentPurchaseOrderLines = this.dropdownPurchaseOrder.purchaseOrderItemDTOList
                ? this.dropdownPurchaseOrder.purchaseOrderItemDTOList
                : [];
        },
        clear() {
            // reset all local data
            this.location = {};
            this.primarySupplier = {};
            this.supplierDocumentNumber = '';
            this.invoiceDate = '';
            this.invoiceTotal = '';
            this.invoiceNotes = '';
            this.poNumber = '';
            this.currentPurchaseOrderLines = [];
            this.dropdownPurchaseOrder = {};
            this.duplicateInvoiceModal = false;
            this.duplicateInvoiceArray = [];
            this.selectedPurchaseOrderLines = [];

            this.clearValidate();
        },
    },
};
</script>

<style lang="scss" scoped>
main {
    grid-area: main;
    margin-bottom: 150px;
}
#warning_modal {
    .warning {
        &__title {
            background-color: var(--v-primary-base);
            color: white;
            position: relative;
            padding: 1rem 1.5rem;
            display: flex;
            justify-content: space-between;
        }

        &__text {
            padding: 1.5rem 1.5rem 1rem;
        }

        &__buttons {
            display: flex;
            justify-content: right;
            padding: 0 1.5rem 1.5rem;
        }
    }
}

.dialog {
    #app &__header {
        padding:1.75rem;
        display:flex;
        justify-content: space-between;
    }
    &__content {
        margin: 2rem;

        &__filter {
            display: flex;
            align-items: flex-start;
            .row &--toggle {
                margin-top: 0.5rem;
                padding-left: 2rem;

                .v-input--selection-controls {
                    margin-top: 0;
                    padding-top: 0;
                }
            }
        }
    }
    &__actions.v-card__actions {
        display: flex;
        justify-content: right;
        padding: 2rem;
        padding-top: 0;
    }
}

.create-invoice {
    margin-left: .25rem;
}

.create-invoice-footer {
    margin-right: .25rem;

    &__button {
        margin-left: 1rem;
        width: 100px;
    }
}
</style>