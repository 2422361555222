<template>
    <div class="sidebar-thin custom-container">
        <TheSidebar />
        <ThePageHeadingBar
            :pageHeading="headerTitle"
            :fullSize="true"
            :backArrow="true"
            @goBack="$router.push( { name: 'InvoiceReconciliationSearch' } )"
        />
        <section class="main">
            <ModalUpdateError
                v-model="showUpdateErrorModal"
                :inFlight="inFlight"
                updateActionPath="Invoice/Detail/update"
                defaultErrorMessage="Error updating Invoice."
            />
            <ModalConfirmation
                v-model="failedToFindInvoiceDialog"
                confirmationTitle="Invoice not found"
                primaryButtonText="Okay"
                maxWidth="200px"
                hideCancelButton
                persistent
                @confirm="goToSearch()"
            />
            <v-card
                class="invoice-detail"
                :loading="inFlight"
            >
                <v-container fluid>
                    <v-row>
                        <v-col
                            cols="12"
                            md="6"
                            lg="4"
                            class="invoice-detail__section"
                        >
                            <v-container>
                                <v-row class='invoice-detail__section--row'>
                                    <v-col cols="4">
                                        <h3>Supplier</h3>
                                    </v-col>
                                    <v-col cols="8">
                                        {{ info.supplier.supplierName }}
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="4">
                                        Invoice Number
                                    </v-col>
                                    <v-col cols="8">
                                        <v-text-field
                                            v-model="supplierDocumentNumber"
                                            dense
                                            outlined
                                            hideDetails
                                            @blur="updateInfo()"
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="4">
                                        Invoice Date
                                    </v-col>
                                    <v-col cols="8">
                                        <InputDatePicker
                                            label="Invoice Date"
                                            :date.sync="date"
                                            outlined
                                            @blur="updateInfo()"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        Due Date
                                    </v-col>
                                    <v-col cols="8">
                                        {{ inFlight ? 'Loading...' : FormatUtil.epochToUTCString( info.dueDate ) }}
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        Return Exp Date
                                    </v-col>
                                    <v-col cols="8">
                                        {{ inFlight ? 'Loading...' : FormatUtil.epochToUTCString( info.returnExpirationDate ) }}
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        Invoice Total
                                    </v-col>
                                    <v-col cols="8">
                                        <InputCurrency v-model="total" @blur="updateInfo()" />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                            lg="4"
                            class="invoice-detail__section"
                        >
                            <v-container>
                                <v-row class="adjustments">
                                    <v-col cols="12">
                                        <h3>Adjustments</h3>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-card>
                                            <v-container>
                                                <v-row class="adjustments__card--header grey lighten-3">
                                                    <v-col cols="4"><h4>Type</h4></v-col>
                                                    <v-col cols="4"><h4>Debit</h4></v-col>
                                                    <v-col cols="4"><h4>Credit</h4></v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="4">
                                                        Fees
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <InputCurrency v-model="adjustmentFees" @blur="updateInfo()" />
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="4">
                                                        Freight
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <InputCurrency v-model="adjustmentFreight" @blur="updateInfo()" />
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="4">
                                                        Tax
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <InputCurrency v-model="adjustmentTax" @blur="updateInfo()" />
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="4">
                                                        Credit
                                                    </v-col>
                                                    <v-col offset="4" cols="4">
                                                        <InputCurrency v-model="adjustmentCredit" @blur="updateInfo()" />
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <h3>Notes</h3>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea
                                            v-model="notes"
                                            outlined
                                            dense
                                            hideDetails
                                            @blur="updateInfo()"
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                            </v-container>

                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                            lg="4"
                            class="invoice-detail__section"
                        >
                            <v-container>
                                <v-row align="center">
                                    <v-col cols="3">
                                        <h3>Status</h3>
                                    </v-col>
                                    <v-col cols="9">
                                        <v-autocomplete
                                            v-model="reconcileStatus"
                                            :disabled="inFlight"
                                            :items="invoiceStatusList"
                                            outlined
                                            dense
                                            hideDetails
                                            @blur="updateInfo()"
                                            @change="checkCompleteModal"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row class="invoice-detail__section__totals">
                                    <v-col cols="3">
                                        <h3>Totals</h3>
                                    </v-col>
                                    <v-col cols="9">
                                        <v-container>
                                            <v-row>
                                                <v-col cols="8">
                                                    Calculated Line Total
                                                </v-col>
                                                <v-col cols="4" class="invoice-detail__section__totals__amount">
                                                    {{ FormatUtil.toCurrency( calculatedLineTotal ) }}
                                                </v-col>
                                            </v-row>
                                            <v-row class="invoice-detail__section--total">
                                                <v-col cols="8">
                                                    Total Adjustments
                                                </v-col>
                                                <v-col cols="4" class="invoice-detail__section__totals__amount">
                                                    {{ FormatUtil.toCurrency( totalAdjustments ) }}
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="8">
                                                    Calculated Total
                                                </v-col>
                                                <v-col cols="4" class="invoice-detail__section__totals__amount">
                                                    {{ FormatUtil.toCurrency( calculatedTotal ) }}
                                                </v-col>
                                            </v-row>
                                            <v-row class="invoice-detail__section--total">
                                                <v-col cols="8">
                                                    Invoice Total
                                                </v-col>
                                                <v-col cols="4" class="invoice-detail__section__totals__amount">
                                                    {{ FormatUtil.toCurrency( total ) }}
                                                </v-col>
                                            </v-row>
                                            <v-row class="invoice-detail__section--difference">
                                                <v-col cols="8">
                                                    Difference
                                                </v-col>
                                                <v-col cols="4" class="invoice-detail__section__totals__amount">
                                                    {{ FormatUtil.toCurrency( difference ) }}
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-container>
                <span class="invoice-detail__delete-button">
                    <v-spacer/>
                    <v-btn
                        :disabled="inFlight"
                        class="delete"
                        color="primary"
                        text
                        @click="deleteConfirmationDialog = true"
                    >
                        Delete
                    </v-btn>
                    <ModalConfirmation
                        v-model="deleteConfirmationDialog"
                        confirmationTitle="Delete Invoice"
                        confirmationText="Are you sure you want to delete the invoice?"
                        primaryButtonText="Delete"
                        headerClass="info"
                        primaryButtonColor="info"
                        cancelButtonColor="info"
                        @confirm="deleteInvoice"
                    />
                </span>
            </v-card>
            <v-expansion-panels class="expansion-panels" :value="0">
                <v-expansion-panel class="unreconciled-purchase-orders">
                    <v-expansion-panel-header>
                        <h2>Purchase Order Lines</h2>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <UnreconciledPurchaseOrders :isDirty.sync="isDirty" />
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-expansion-panels class="expansion-panels" :value="0">
                <!-- ASSOCIATED PURCHASE ORDERS-->
                <v-expansion-panel class="associated-purchase-orders">
                    <v-expansion-panel-header>
                        <h2>Associated Purchase Orders</h2>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <AssociatedPurchaseOrders />
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <ModalPurchaseOrderCompletion
                v-model="showCompleteModal"
                :associatedPurchaseOrders="associatedPurchaseOrders"
                @clear="associatedPurchaseOrders = []"
            />
        </section>
    </div>
</template>

<script>
// Vuex
import { mapGetters, mapState, mapActions } from 'vuex';
import { fullNameSpace, mutationTypes, actionTypes } from '@/store/modules/Invoice/Detail';

// Routes
import InvoiceRoute from '@/router/routes/InvoiceReconciliation';

// Utils
import FormatUtil from '@/utils/FormatUtil';

// Components
import TheSidebar from '@/views/common/TheSidebar';
import ThePageHeadingBar from '@/components/ThePageHeadingBar';
import ModalConfirmation from '@/components/modals/ModalConfirmation';
import InputCurrency from '@/components/base/inputs/InputCurrency';
import ModalUpdateError from '@/components/modals/ModalUpdateError';
import InputDatePicker from '@/components/InputDatePicker';
import AssociatedPurchaseOrders from './detail/AssociatedPurchaseOrders';
import UnreconciledPurchaseOrders from './detail/UnreconciledPurchaseOrders';
import ModalPurchaseOrderCompletion from './detail/ModalPurchaseOrderCompletion';

export default {
    name: 'InvoiceDetail',
    components: {
        InputDatePicker,
        TheSidebar,
        ThePageHeadingBar,
        ModalUpdateError,
        ModalConfirmation,
        InputCurrency,
        AssociatedPurchaseOrders,
        UnreconciledPurchaseOrders,
        ModalPurchaseOrderCompletion,
    },
    beforeRouteLeave( to, from, next ) {
        if ( this.isDirty ) {
            // A browser alert is needed in order to stop the routing process
            // eslint-disable-next-line no-alert
            if ( !window.confirm( 'Leave without saving?' ) ) {
                return;
            }
        }
        next();
    },
    data() {
        return {
            FormatUtil,
            showAddPOLineDialog: false,
            showUpdateErrorModal: false,
            showCompleteModal: false,
            deleteConfirmationDialog: false,
            failedToFindInvoiceDialog: false,
            isDirty: false,
            associatedPurchaseOrders: [],
        };
    },
    computed: {
        ...mapGetters( {
            calculatedLineTotal: 'Invoice/Detail/totalsCalculatedLineTotal',
            totalAdjustments: 'Invoice/Detail/totalsTotalAdjustments',
            calculatedTotal: 'Invoice/Detail/totalsCalculatedTotal',
            difference: 'Invoice/Detail/totalsDifference',
        } ),
        ...mapState( {
            associatedInFlight: ( state ) => state.Invoice.Detail.associatedPurchaseOrdersInFlight,
            info: ( state ) => state.Invoice.Detail.info,
            inFlight: ( state ) => state.Invoice.Detail.inFlight,
            invoiceStatusList: ( state ) => state.Invoice.invoiceStatusList,
        } ),
        /** @returns { String } */
        headerTitle() {
            let header = this.$route.meta.title;

            if ( this.info.supplier && this.info.supplier.supplierName ) {
                header += ` - ${ this.info.supplier.supplierName }`;
            }
            if ( this.info.date ) {
                header += `, ${ FormatUtil.epochToUTCString( this.info.date ) }`;
            }
            return header;
        },

        supplierDocumentNumber: {
            get() {
                return this.info.supplierDocumentNumber;
            },
            set( value ) {
                this.isDirty = true;
                this.$store.commit( `${ fullNameSpace }${ mutationTypes.SET_SUPPLIER_DOCUMENT_NUMBER }`, { value } );
            },
        },
        date: {
            get() {
                return FormatUtil.epochToUTCString( this.info.date, { format: 'YYYY-MM-DD' } );
            },
            set( value ) {
                this.isDirty = true;
                this.$store.commit( `${ fullNameSpace }${ mutationTypes.SET_DATE }`, { value: FormatUtil.dateToEpoch( value ) } );
            },
        },
        total: {
            get() {
                return this.info.total;
            },
            set( value ) {
                this.isDirty = true;
                this.$store.commit( `${ fullNameSpace }${ mutationTypes.SET_TOTAL }`, { value } );
            },
        },
        termsDiscount: {
            get() {
                return this.info.termsDiscount;
            },
            set( value ) {
                this.isDirty = true;
                this.$store.commit( `${ fullNameSpace }${ mutationTypes.SET_TERMS_DISCOUNT }`, { value } );
            },
        },
        termsDays: {
            get() {
                return this.info.termsDays;
            },
            set( value ) {
                this.isDirty = true;
                this.$store.commit( `${ fullNameSpace }${ mutationTypes.SET_TERMS_DAYS }`, { value } );
            },
        },
        notes: {
            get() {
                return this.info.notes;
            },
            set( value ) {
                this.isDirty = true;
                this.$store.commit( `${ fullNameSpace }${ mutationTypes.SET_NOTES }`, { value } );
            },
        },
        reconcileStatus: {
            get() {
                return this.info.reconcileStatus;
            },
            set( value ) {
                this.isDirty = true;
                this.$store.commit( `${ fullNameSpace }${ mutationTypes.SET_RECONCILE_STATUS }`, { value } );
            },
        },
        adjustmentFees: {
            get() {
                return this.info.adjustments.FEES.amount;
            },
            set( value ) {
                this.isDirty = true;
                this.$store.commit( `${ fullNameSpace }${ mutationTypes.SET_ADJUSTMENT_FEES }`, { value } );
            },
        },
        adjustmentFreight: {
            get() {
                return this.info.adjustments.FREIGHT.amount;
            },
            set( value ) {
                this.isDirty = true;
                this.$store.commit( `${ fullNameSpace }${ mutationTypes.SET_ADJUSTMENT_FREIGHT }`, { value } );
            },
        },
        adjustmentTax: {
            get() {
                return this.info.adjustments.TAX.amount;
            },
            set( value ) {
                this.isDirty = true;
                this.$store.commit( `${ fullNameSpace }${ mutationTypes.SET_ADJUSTMENT_TAX }`, { value } );
            },
        },
        adjustmentCredit: {
            get() {
                return this.info.adjustments.CREDIT.amount;
            },
            set( value ) {
                this.isDirty = true;
                this.$store.commit( `${ fullNameSpace }${ mutationTypes.SET_ADJUSTMENT_CREDIT }`, { value } );
            },
        },
    },
    beforeMount() {
        window.addEventListener( 'beforeunload', this.preventNav );
        this.$once( 'hook:beforeDestroy', () => {
            window.removeEventListener( 'beforeunload', this.preventNav );
        } );
    },
    async mounted() {
        if ( this.$route.params.id ) {
            await this.$store.dispatch( 'Invoice/getInvoiceStatusList' );
            await this.getInvoiceDetails();
        }
    },
    async beforeDestroy() {
        await this.$store.dispatch( 'Invoice/Detail/reset' );
    },
    methods: {
        ...mapActions( {
            getAssociatedPurchaseOrders: 'Invoice/Detail/getAssociatedPurchaseOrdersByInvoiceId',
        } ),

        // This function prevents the browser from navigating while editing.
        preventNav( event ) {
            if ( !this.isDirty ) return;
            event.preventDefault();
            // eslint-disable-next-line no-param-reassign
            event.returnValue = '';
        },
        async goToSearch() {
            await this.$router.push( InvoiceRoute.path );
        },
        async getInvoiceDetails() {
            try {
                await this.$store.dispatch( 'Invoice/Detail/get', { invoiceId: this.$route.params.id } );
            } catch ( error ) {
                this.failedToFindInvoiceDialog = true;
            }
        },
        async updateInfo() {
            try {
                this.isDirty = true;
                await this.$store.dispatch( `${ fullNameSpace }${ actionTypes.update }` );
                this.isDirty = false;
            } catch ( error ) {
                this.showUpdateErrorModal = true;
            }
        },
        async checkCompleteModal( status ) {
            if ( status.toLowerCase() === 'complete' ) {
                this.showCompleteModal = true;
                this.associatedPurchaseOrders = await this.getAssociatedPurchaseOrders();
            }
        },
        async deleteInvoice() {
            try {
                await this.$store.dispatch( `${ fullNameSpace }${ actionTypes.delete }` );
                this.isDirty = false;
            } catch ( e ) {
                throw new Error( e );
            }
            this.$router.push( { name: 'InvoiceReconciliationSearch' } );
        },
    },
};
</script>

<style lang="scss" scoped>
.invoice-detail {
    margin-bottom: 1rem;
    &__section {
        &--total {
            border-bottom: solid 1px;
        }
        &--difference {
            border-bottom: solid 2px;
        }
        &__totals {
            &__amount {
                text-align: right;
                overflow: visible;
                white-space: nowrap
            }
        }
        &:last-of-type {
            margin-bottom: 3rem;
        }
    }
    &__delete-button {
        position: absolute;
        bottom:0;
        right:0;
        .delete {
            margin-right: 1.5rem;
            margin-bottom: 1rem;
        }
    }
}

.expansion-panels {
    margin-bottom: 1rem;
}
</style>
